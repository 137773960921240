body .flip-clock-wrapper ul li a div div.inn,
body .flip-clock-small-wrapper ul li a div div.inn {
    color: #31bfc3;
    background-color: #333333;
}

body .flip-clock-dot,
body .flip-clock-small-wrapper .flip-clock-dot {
    background: #323434;
}

body .flip-clock-wrapper .flip-clock-meridium a,
body .flip-clock-small-wrapper .flip-clock-meridium a {
    color: #323434;
}
.flip-clock-divider .flip-clock-label {
	color: #fff;
}

.b-speakers_view {
    opacity: 1;
}