/* -------------------- Table of contents --------------------

Author Name: Loginov Artem
Author URI: http://loginov.biz
ThemeForest: http://themeforest.net/user/noblige?ref=noblige
Description: A default stylesheet for Eventual Landing Page Template

1. Reset
2. Global
3. Typographic
4. Transitions
5. Blocks / elements
6. Header
7. Sections
    7.1. Intro
    7.2. Features
    7.3. Overview
    7.4. Counters
    7.5. Schedule
    7.6. Subscribe
    7.7. Speakers
    7.8. Pricing plans
    7.9. FAQ
    7.10. Sign Up
    7.11. Partners and sponsors
    7.12. Contacts
8. Animations

   -------------------- Table of content -------------------- */


/* --------------------
   -------------------- Reset -------------------- */

* {
    margin: 0;
    outline: none;
}

header,
nav,
section,
article,
aside,
footer {
    display: block;
}

body,
p,
ul,
li,
ol,
pre,
caption,
h1,
h2,
h3,
h4,
h5,
h6,
form,
div,
fieldset,
legend {
    padding: 0;
}

html {
    width: 100%;
    height: 100%;
}

body {
    position: relative;
    min-height: 100%;
    height: 100%;
    width: 100%;
    max-width: 100%;
    min-width: 320px;
    overflow-x: hidden;
    font: 400 0.625em 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: scrollbar;
}

img,
fieldset {
    border: 0;
}

input,
textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

input::-moz-focus-inner,
textarea::-moz-focus-inner,
select::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

input[type="text"],
input[type="password"],
button,
textarea {
    -webkit-appearance: none;
}

input::-ms-clear,
textarea::-ms-clear {
    visibility: hidden;
}

a,
button {
    text-decoration: none;
    -webkit-transition: color 400ms, background 400ms, border 400ms, opacity 400ms;
    -moz-transition: color 400ms, background 400ms, border 400ms, opacity 400ms;
    transition: color 400ms, background 400ms, border 400ms, opacity 400ms;
}


/* --------------------
   -------------------- Global -------------------- */

.m-affix {
    position: fixed;
}

.m-hidden {
    display: none;
    visibility: hidden;
}

.m-hidden-text {
    font-size: 0;
    color: transparent;
    text-shadow: none;
}

.m-pull-left {
    float: left;
    margin: 0 24px 12px 0;
}

.m-pull-right {
    float: right;
    margin: 0 0 12px 24px;
}

.m-left {
    text-align: left;
}

.m-center {
    text-align: center;
}

.m-right {
    text-align: right;
}

.m-nowrap {
    white-space: nowrap;
}

.m-perspective {
    -webkit-perspective: 1500px;
    -moz-perspective: 1500px;
    perspective: 1500px;
    -webkit-perspective-origin: 50% 50%;
    -moz-perspective-origin: 50% 50%;
    perspective-origin: 50% 50%;
}

.m-transition {
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    -o-transition: all 400ms;
    transition: all 400ms;
}


/* * * * * *
 * * * * * * Buttons * * * * * */

.e-btn {
    display: inline-block;
    position: relative;
    text-decoration: none !important;
    text-align: center;
    outline: none;
    cursor: pointer;
    border: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.e-btn_md {
    padding: 16px 50px;
    font: 700 18px/1 'arial', sans-serif;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.e-btn_lg {
    padding: 16px 75px;
    font: 700 18px/1 'arial', sans-serif;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

@media (max-width: 767px) {
    .e-btn {
        display: block;
        padding: 16px 35px;
        width: 100%;
    }
}

.e-btn_solid {
    border: none;
}

.e-btn_outline {
    border-width: 2px;
    border-style: solid;
}

.e-btn_outline_white {
    color: #fff;
    background: rgba(255, 255, 255, .1);
    border: 2px solid #fff;
}

.e-btn_block {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
}

.e-btn_solid[disabled] {
    color: #fff !important;
    background: #999 !important;
}

.e-btn_outline[disabled] {
    color: #999 !important;
    border-color: #dedede !important;
}


/* * * * * *
 * * * * * * Btn effects * * * * * */

.e-btn_smooth {
    overflow: hidden;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
}

.e-btn_smooth span {
    display: block;
    position: relative;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: all 250ms cubic-bezier(0.2, 1, 0.3, 1);
    -moz-transition: all 250ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: all 250ms cubic-bezier(0.2, 1, 0.3, 1);
}

.e-btn_smooth:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: inherit;
    content: attr(data-label);
    opacity: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform: translate(0, 25%);
    -moz-transform: translate(0, 25%);
    transform: translate(0, 25%);
    -webkit-transition: all 250ms cubic-bezier(0.2, 1, 0.3, 1);
    -moz-transition: all 250ms cubic-bezier(0.2, 1, 0.3, 1);
    transition: all 250ms cubic-bezier(0.2, 1, 0.3, 1);
}

.e-btn_smooth:hover span {
    opacity: 0;
    -webkit-transform: translate(0, -25%);
    -moz-transform: translate(0, -25%);
    transform: translate(0, -25%);
}

.e-btn_smooth:hover:after {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
}


/* * * * * *
 * * * * * * Btn drop icon * * * * * */

.e-btn_dropIcon {
    overflow: hidden;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
}

.e-btn_dropIcon span {
    display: block;
    position: relative;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    -o-transition: all 400ms;
    transition: all 400ms;
}

.e-btn_dropIcon:before {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    margin-top: -16px;
    font: 400 32px/1 'Fontello', sans-serif;
    text-align: center;
    -webkit-transform: translate(0, -64px);
    -moz-transform: translate(0, -64px);
    -ms-transform: translate(0, -64px);
    -o-transform: translate(0, -64px);
    transform: translate(0, -64px);
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    -o-transition: all 400ms;
    transition: all 400ms;
}

.e-btn_dropIcon:hover span {
    -webkit-transform: translate(0, 64px);
    -moz-transform: translate(0, 64px);
    -ms-transform: translate(0, 64px);
    -o-transform: translate(0, 64px);
    transform: translate(0, 64px);
    opacity: 0;
}

.e-btn_dropIcon:hover:before {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
}


/* * * * * *
 * * * * * * Icons * * * * * */

.i-icon:before,
.i-icon-after:after {
    width: auto;
    line-height: 1;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    text-decoration: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.i-icon[class*="-el"]:before,
.i-icon-after[class*="-el"]:after {
    font-family: 'EtLine', sans-serif;
}

.i-icon[class*="-fa"]:before,
.i-icon-after[class*="-fa"]:after {
    font-family: 'FontAwesome', sans-serif;
}

.i-icon[class*="-ion"]:before,
.i-icon-after[class*="-ion"]:after {
    font-family: 'Ionicons', sans-serif;
}


/* --------------------
   -------------------- Typographic -------------------- */

.b-wysiwyg h3 {
    margin-bottom: 1.3125em;
    font: 700 1em/1.25 'arial', sans-serif;
    letter-spacing: .05em;
    text-transform: uppercase;
}

.b-wysiwyg h4 {
    margin-bottom: 1.3125em;
    font: 900 1em/1.25 'arial', sans-serif;
    letter-spacing: .05em;
}

.b-wysiwyg h5 {
    margin-bottom: 1.3125em;
    font: 900 1em/1.25 'arial', sans-serif;
    letter-spacing: .05em;
}

.b-wysiwyg h6 {
    margin-bottom: 1.3125em;
    font: 900 1em/1.25 'arial', sans-serif;
    letter-spacing: .05em;
}

.b-wysiwyg a {
    text-decoration: none;
}

.b-wysiwyg a:hover {
    color: inherit;
}

.b-wysiwyg p {
    margin: 12px 0;
}

.b-wysiwyg hr {
    margin: 12px 0;
    border: none;
    border-bottom: 1px solid #dedede;
}

.b-wysiwyg table {
    margin: 12px 0;
}

.b-wysiwyg * ~ h3,
.b-wysiwyg * ~ h4,
.b-wysiwyg * ~ h5,
.b-wysiwyg * ~ h6 {
    margin-top: 36px;
}

.b-wysiwyg *:first-child {
    margin-top: 0;
}

.b-wysiwyg ul,
.b-wysiwyg ol {
    list-style: none;
    margin: 16px 0 16px 20px;
}

.b-wysiwyg li ul,
.b-wysiwyg li ol {
    margin: 15px 0 0 0;
}

.b-wysiwyg li {
    position: relative;
    padding-left: 28px;
    margin-bottom: 10px;
}

.b-wysiwyg li:before {
    position: absolute;
    left: 0;
    top: 0;
}

.b-wysiwyg ol,
.b-wysiwyg ul ol {
    counter-reset: list;
}

.b-wysiwyg ol ol {
    counter-reset: sublist;
}

.b-wysiwyg ol ol ol {
    counter-reset: thirdlist;
}

.b-wysiwyg ol > li:before {
    top: -1px;
    font: 700 1.2em 'arial', sans-serif;
    counter-increment: list;
    content: counter(list) ".";
}

.b-wysiwyg ol li ol li:before {
    counter-increment: sublist;
    content: counter(list) "." counter(sublist) ".";
}

.b-wysiwyg ol li ol li ol li:before {
    counter-increment: thirdlist;
    content: counter(list) "." counter(sublist) "." counter(thirdlist) ".";
}

.b-wysiwyg ol li ol li {
    padding-left: 48px;
}

.b-wysiwyg ol li ol li ol li {
    padding-left: 64px;
}

.b-wysiwyg ul > li:before {
    font: normal 1em/1.5 'Ionicons', sans-serif;
    content: '\f382';
}

.b-wysiwyg blockquote {
    margin-left: 20px;
    padding-left: 20px;
    font: 300 italic 1em/1.3125em 'arial', sans-serif;
    border-left: 4px solid #f5f5f5;
}

.b-wysiwyg blockquote p {
    margin: 8px 0;
}

.b-wysiwyg blockquote cite {
    font-style: normal;
    font-size: .875em;
    color: #999;
}

.b-wysiwyg blockquote cite:before {
    content: '\2014\00A0';
}

.b-wysiwyg .e-icon {
    font-size: 24px;
}


/* --------------------
   -------------------- Transitions -------------------- */


/* * * * * *
 * * * * * * Tabs * * * * * */

.js-tabs-wrapper {
    position: relative;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.js-tabs-page {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    -webkit-transition: visibility 150ms ease, opacity 150ms ease;
    -o-transition: visibility 150ms ease, opacity 150ms ease;
    transition: visibility 150ms ease, opacity 150ms ease;
}

.js-tabs-page.visible {
    position: static;
    visibility: visible;
    opacity: 1;
}

.js-tabs-page .js-tabs-page {
    visibility: hidden;
    opacity: 0;
}

.js-tabs-page.visible .js-tabs-page.visible {
    position: static;
    visibility: visible;
    opacity: 1;
}


/* * * * * *
 * * * * * * Spoilers * * * * * */

.js-spoiler-text {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 400ms, visibility 400ms;
    -moz-transition: opacity 400ms, visibility 400ms;
    -o-transition: opacity 400ms, visibility 400ms;
    transition: opacity 400ms, visibility 400ms;
}

.js-spoiler.opened .js-spoiler-text {
    visibility: visible;
    opacity: 1;
}


/* --------------------
   -------------------- Blocks / elements -------------------- */


/* * * * * *
 * * * * * * Logotype * * * * * */

.e-logotype small {
    font-size: .875em;
}


/* * * * * *
 * * * * * * Frame angles * * * * * */

.e-frame:after,
.e-frame:before {
    position: absolute;
    content: '';
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    transition: all 400ms;
}

.e-frame__top:after {
    left: 0;
    top: 0;
    border-left-width: 1px;
    border-left-style: solid;
    border-top-width: 1px;
    border-top-style: solid;
}

.e-frame__top:before {
    right: 0;
    top: 0;
    border-right-width: 1px;
    border-right-style: solid;
    border-top-width: 1px;
    border-top-style: solid;
}

.e-frame__bottom:after {
    left: 0;
    bottom: 0;
    border-left-width: 1px;
    border-left-style: solid;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.e-frame__bottom:before {
    right: 0;
    bottom: 0;
    border-right-width: 1px;
    border-right-style: solid;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.e-frame__xs:after,
.e-frame__xs:before {
    width: 15px;
    height: 15px;
}

.e-frame__sm:after,
.e-frame__sm:before {
    width: 20px;
    height: 20px;
}

.e-frame__md:after,
.e-frame__md:before {
    width: 30px;
    height: 30px;
}

.e-frame__lg:after,
.e-frame__lg:before {
    width: 40px;
    height: 40px;
}

.e-frame__xl:after,
.e-frame__xl:before {
    width: 50px;
    height: 50px;
}


/* * * * * *
 * * * * * * Tabs * * * * * */

.b-tabs {
    list-style: none;
    margin-bottom: 30px;
}

.b-tabs li {
    display: block;
    border-bottom: 1px solid #dedede;
}

.b-tabs a {
    display: block;
    position: relative;
    padding: 15px;
    font: 300 .875em 'arial', sans-serif;
    text-decoration: none;
    color: #aaa;
}

.b-tabs a strong {
    font: 700 1.28571428571429em 'arial', sans-serif;
}

.b-tabs a:hover,
.b-tabs a.current {
    color: #555;
}

.b-tabs a:before {
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    margin: 0 auto;
    height: 1px;
    background-color: transparent;
    content: '';
    -webkit-transform: scale(0, 1);
    -moz-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    transition: all 400ms;
}

.b-tabs a.current:before {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}

@media (min-width: 768px) {
    .b-tabs {
        display: table;
        table-layout: fixed;
        margin-bottom: 60px;
        width: 100%;
        border-collapse: collapse;
        border-bottom: 1px solid #dedede;
    }
    .b-tabs li {
        display: table-cell;
        vertical-align: bottom;
        text-align: center;
    }
    .b-tabs a {
        padding: 0 15px 15px;
    }
}


/* * * * * *
 * * * * * * Carousels * * * * * */

.b-carousel {
    position: relative;
}

.b-carousel > .b-carousel_item {
    float: left;
    width: 25%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.b-carousel.owl-carousel {
    letter-spacing: normal;
}

.b-carousel .owl-nav > div {
    position: absolute;
    z-index: 10;
    top: 50%;
    margin-top: -24px;
    padding: 16px 0;
    width: 80px;
    font: 900 1em/1 'arial', sans-serif;
    text-transform: uppercase;
    text-align: center;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    transition: all 400ms;
}

.b-carousel .owl-nav > div .e-frame:after,
.b-carousel .owl-nav > div .e-frame:before {
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
}

@media (max-width: 767px) {
    .b-carousel .owl-nav {
        display: none;
    }
}

@media (min-width: 768px) {
    .b-carousel .owl-nav .owl-prev {
        left: -125px;
    }
    .b-carousel .owl-nav .owl-next {
        right: -125px;
    }
}

@media (min-width: 992px) {
    .b-carousel .owl-nav .owl-prev {
        left: -80px;
    }
    .b-carousel .owl-nav .owl-next {
        right: -80px;
    }
}


/* * * * * *
 * * * * * * Sliders * * * * * */

.b-slider {
    position: relative;
}

.b-slider_viewPort {
    height: 100%;
}

.b-slider_inner {
    position: relative;
    list-style: none;
    height: 100%;
}

.b-slider_inner:after {
    display: table;
    clear: both;
    content: '';
}

.b-slider_inner > div {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.b-slider_inner > div + div {
    display: none;
}

.b-slider_control_paging {
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 25px;
    right: 0;
    text-align: center;
}

.b-slider_control_paging .b-slider_bullet {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
}

.b-slider_control_paging .b-slider_bullet a {
    display: block;
    overflow: hidden;
    width: 12px;
    height: 12px;
    text-indent: 10000px;
    color: #fff;
    background: transparent;
    border-width: 2px;
    border-style: solid;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    transition: all 400ms;
}

.b-slider_control_paging .b-slider_bullet a.b-slider_active {
    background: #fff;
    -webkit-transform: scale(1.3, 1.3);
    -moz-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
}

.b-slider_control_paging .b-slider_bullet a:hover {
    opacity: .65;
}


/* * * * * *
 * * * * * * Gallery * * * * * */

.b-gallery {
    position: relative;
    margin-bottom: 30px;
}

.b-gallery .b-slider_viewPort,
.b-gallery .b-slider .b-slider_viewPort {
    height: auto !important;
}

.b-gallery_item {
    position: relative;
    overflow: hidden;
}

.b-gallery_item_pic {
    display: block;
    width: 100%;
    height: auto;
    max-height: 360px;
}

.b-gallery_item_overlay {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    opacity: 0;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    transition: all 400ms;
}

.b-gallery_item_overlay_inner {
    display: table;
    table-layout: fixed;
    width: 101%;
    height: 100%;
    border-collapse: collapse;
}

.b-gallery_item_overlay_inner_cell {
    display: table-cell;
    vertical-align: middle;
    padding: 15px;
    text-align: center;
    font: 400 1em/1.3125 'arial', sans-serif;
    color: #fff;
}

.b-gallery_item_overlay_inner_cell:before {
    display: block;
    position: relative;
    margin: 0 auto 32px;
    width: 42px;
    height: 42px;
    font-size: 42px;
    line-height: 42px;
    text-align: center;
    -webkit-transform: scale(0, 0);
    -moz-transform: scale(0, 0);
    transform: scale(0, 0);
}

.b-gallery_item_overlay_inner_cell h3 {
    margin: 8px 0;
    font: 900 1.125em 'arial', sans-serif;
    text-transform: uppercase;
    letter-spacing: .05em;
}

.b-gallery_item_overlay_inner_cell p {
    margin: 8px 0;
    -webkit-transform: translate(0, 25%);
    -moz-transform: translate(0, 25%);
    transform: translate(0, 25%);
}

@media (max-width: 479px) {
    .b-gallery_item_overlay_inner_cell:before {
        display: none;
    }
}

.b-gallery_item_overlay_inner_cell p {
    -webkit-transition: all 600ms;
    -moz-transition: all 600ms;
    transition: all 600ms;
}

.b-gallery_item_overlay_inner_cell:before {
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    transition: all 400ms;
}

.b-gallery_item:hover .b-gallery_item_overlay {
    opacity: 1;
}

.b-gallery_item:hover .b-gallery_item_overlay_inner_cell *,
.b-gallery_item:hover .b-gallery_item_overlay_inner_cell:before {
    -webkit-transform: translate(0, 0) scale(1, 1);
    -moz-transform: translate(0, 0) scale(1, 1);
    transform: translate(0, 0) scale(1, 1);
}

.b-gallery_item:hover .e-frame__top:after {
    -webkit-transform: translate(30px, 30px);
    -moz-transform: translate(30px, 30px);
    -ms-transform: translate(30px, 30px);
    transform: translate(30px, 30px);
}

.b-gallery_item:hover .e-frame__top:before {
    -webkit-transform: translate(-30px, 30px);
    -moz-transform: translate(-30px, 30px);
    -ms-transform: translate(-30px, 30px);
    transform: translate(-30px, 30px);
}

.b-gallery_item:hover .e-frame__bottom:after {
    -webkit-transform: translate(30px, -30px);
    -moz-transform: translate(30px, -30px);
    -ms-transform: translate(30px, -30px);
    transform: translate(30px, -30px);
}

.b-gallery_item:hover .e-frame__bottom:before {
    -webkit-transform: translate(-30px, -30px);
    -moz-transform: translate(-30px, -30px);
    -ms-transform: translate(-30px, -30px);
    transform: translate(-30px, -30px);
}

@media (max-width: 479px) {
    .b-gallery_item:hover .e-frame__top:after {
        -webkit-transform: translate(20px, 20px);
        -moz-transform: translate(20px, 20px);
        -ms-transform: translate(20px, 20px);
        transform: translate(20px, 20px);
    }
    .b-gallery_item:hover .e-frame__top:before {
        -webkit-transform: translate(-20px, 20px);
        -moz-transform: translate(-20px, 20px);
        -ms-transform: translate(-20px, 20px);
        transform: translate(-20px, 20px);
    }
    .b-gallery_item:hover .e-frame__bottom:after {
        -webkit-transform: translate(20px, -20px);
        -moz-transform: translate(20px, -20px);
        -ms-transform: translate(20px, -20px);
        transform: translate(20px, -20px);
    }
    .b-gallery_item:hover .e-frame__bottom:before {
        -webkit-transform: translate(-20px, -20px);
        -moz-transform: translate(-20px, -20px);
        -ms-transform: translate(-20px, -20px);
        transform: translate(-20px, -20px);
    }
}

.b-gallery_item_link {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .00001);
}


/* * * * * *
 * * * * * * Countdown * * * * * */

.b-countdown {
    text-align: center;
    white-space: nowrap;
}

.b-countdown_finished,
.b-countdown_caption {
    margin-bottom: 20px;
    font: 900 1em 'arial', sans-serif;
    text-transform: uppercase;
}

.b-countdown_dash {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin: 0 7px;
}

.b-countdown_dash_title {
    display: block;
    margin-top: 12px;
    font: 700 1em 'arial', sans-serif;
    letter-spacing: .025em;
}

.b-countdown_dash_inner {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 48px;
    height: 54px;
    letter-spacing: -4px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.b-countdown_dash_inner .e-frame:after,
.b-countdown_dash_inner .e-frame:before {
    width: 15px;
    height: 20px;
}

.b-countdown_dash_digit {
    display: inline-block;
    vertical-align: top;
    margin: 7px 0;
    width: 14px;
    height: 40px;
    font: 300 1.625em/40px 'Oswald', sans-serif;
    letter-spacing: normal;
    overflow: hidden;
}


/* * * * * *
 * * * * * * Countdown finish * * * * * */

.b-countdown_dash,
.b-countdown ~ .b-form_bottom {
    opacity: 1;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    transition: all 400ms;
}


/*.b-countdown__complete .b-countdown_dash,*/

.b-countdown__complete ~ .b-form_bottom {
    opacity: 0;
}

.b-countdown_finished {
    display: none;
}

.b-countdown__complete .b-countdown_finished {
    display: block;
}

.b-countdown__complete .b-countdown_caption {
    display: none;
}


/* --------------------
   -------------------- Header -------------------- */

.b-header {
    position: relative;
    padding: 25px 0;
    font: 300 1.6em/1.3125 'arial', sans-serif;
    -webkit-transition: all 600ms;
    -moz-transition: all 600ms;
    -o-transition: all 600ms;
    transition: all 600ms;
}

.b-header:before {
    position: absolute;
    z-index: 5;
    right: 50%;
    top: 100%;
    width: 100%;
    height: 1px;
    content: '';
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    transition: all 400ms ease;
}

.b-header:after {
    position: absolute;
    z-index: 5;
    left: 50%;
    top: 100%;
    width: 100%;
    height: 1px;
    content: '';
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    transition: all 400ms ease;
}

.b-header__transparent {
    padding: 40px 0;
}

@media (min-width: 768px) {
    .b-header__transparent:before {
        background: #fff;
        -webkit-transform: translate(-320px, 0);
        -moz-transform: translate(-320px, 0);
        -ms-transform: translate(-320px, 0);
        transform: translate(-320px, 0);
    }
    .b-header__transparent:after {
        background: #fff;
        -webkit-transform: translate(320px, 0);
        -moz-transform: translate(320px, 0);
        -ms-transform: translate(320px, 0);
        transform: translate(320px, 0);
    }
}

.b-header__float {
    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
    right: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}


/* * * * * *
 * * * * * * Logotype * * * * * */

.b-header_logo {
    display: inline-block;
    position: relative;
}

.b-header_logo__pic img {
    display: block;
    visibility: visible;
    opacity: 1;
}

.b-header_logo__pic img + img {
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
}

.b-header__transparent .b-header_logo__pic img {
    visibility: hidden;
    opacity: 0;
}

.b-header__transparent .b-header_logo__pic img + img {
    visibility: visible;
    opacity: 1;
}

.b-header_logo__text {
    height: 22px;
    font: 900 1.75em/.78571428571429 'arial', sans-serif;
    letter-spacing: .025em;
    -webkit-transition: color 400ms;
    -moz-transition: color 400ms;
    transition: color 400ms;
}

.b-header_logo__text small {
    font-weight: 700;
    font-size: .78571428571429em;
}

.b-header__transparent .b-header_logo__text {
    color: #fff;
}


/* * * * * *
 * * * * * * Navigation * * * * * */

.b-header_nav {
    font: 700 .875em/1.25 'arial', sans-serif;
    text-transform: uppercase;
    letter-spacing: .05em;
}

@media (max-width: 767px) {
    .b-header_nav {
        position: absolute;
        z-index: 0;
        left: 0;
        top: 100%;
        width: 100%;
        padding: 12px 0;
        visibility: hidden;
        opacity: 0;
    }
    .b-header_nav a {
        display: block;
        padding: 12px 15px;
        text-align: center;
    }
    .b-header__showNav .b-header_nav {
        visibility: visible;
        opacity: 1;
    }
}

@media (min-width: 768px) {
    .b-header_nav {
        padding: 5px 0 0;
        text-align: right;
    }
    .b-header_nav a {
        display: inline-block;
        position: relative;
        text-decoration: none;
    }
    .b-header_nav a ~ a {
        margin-left: 14px;
    }
    .b-header_nav a:before {
        position: absolute;
        left: 0;
        top: 100%;
        right: 0;
        margin: 7px auto 0;
        height: 2px;
        background-color: transparent;
        content: '';
        -webkit-transform: scale(0, 1);
        -moz-transform: scale(0, 1);
        -ms-transform: scale(0, 1);
        transform: scale(0, 1);
        -webkit-transition: all 400ms;
        -moz-transition: all 400ms;
        transition: all 400ms;
    }
    .b-header_nav a.active:before {
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        transform: scale(1, 1);
    }
    .b-header__transparent .b-header_nav {
        padding: 8px 0 0;
    }
}

@media (min-width: 970px) {
    .b-header_nav a ~ a {
        margin-left: 32px;
    }
}

.b-header_nav_toggle {
    position: absolute;
    right: 15px;
    top: 20px;
    width: 32px;
    height: 32px;
    font-size: 32px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
}

.b-header__showNav .b-header_nav_toggle {
    top: 20px;
}

.b-header__transparent .b-header_nav_toggle {
    top: 35px;
}


/* --------------------
   -------------------- Sections -------------------- */

.b-section {
    padding: 40px 0;
    min-width: 320px;
    overflow: hidden;
    font: 300 1.6em/1.5 'arial', sans-serif;
    letter-spacing: .025em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.b-section__solid {}

@media (min-width: 768px) {
    .b-section {
        padding: 80px 0;
    }
}


/* * * * * *
 * * * * * * Section header * * * * * */

.b-section_header {
    position: relative;
    text-align: center;
    margin-bottom: 25px;
}

@media (min-width: 768px) {
    .b-section_header {
        margin-bottom: 45px;
    }
    .b-section_header__stroked {
        margin-bottom: 60px;
    }
}

.b-section_header h2 {
    margin-bottom: .25em;
    font: 400 1.875em/1 'arial', sans-serif;
}

.b-section_header p {
    margin-top: .75em;
    font: 300 1em/1.5 'arial', sans-serif;
}

.b-section_header .i-icon {
    position: relative;
    top: 2px;
    margin-right: 12px;
    font-size: 31px;
}

.b-section_header .i-icon.i-ion-email {
    top: 4px;
}

.b-section_header__stroked h2 {
    position: relative;
    padding-bottom: .5em;
}

.b-section_header__stroked h2:after,
.b-section_header__stroked h2:before {
    position: absolute;
    top: 100%;
    width: 66px;
    height: 1px;
    content: '';
}

.b-section_header__stroked h2:after {
    left: 50%;
    margin-left: -15px;
}

.b-section_header__stroked h2:before {
    margin-top: -2px;
    right: 50%;
    margin-right: -15px;
}


/* * * * * *
 * * * * * * Section with fixed background * * * * * */

.b-section__background {
    padding: 0 !important;
    background-position: center center;
    background-repeat: no-repeat;
}

.b-section__background_overlay {
    padding: 40px 0;
    background: rgba(0, 0, 0, .6);
}

@media (min-width: 768px) {
    .m-desktop .b-section__background {
        background-attachment: fixed;
    }
    .b-section__background_overlay {
        padding: 70px 0;
    }
}

.b-section__background .b-section_header,
.b-section__background .b-section_header h2 {
    color: #fff;
}

.b-section__background .b-section_header p {
    font-weight: 400;
}


/* --------------------
   -------------------- Sections :: Intro -------------------- */

.b-intro {
    padding: 0;
    overflow: hidden;
}

.b-intro_wrapper {
    display: table;
    position: relative;
    z-index: 5;
    padding: 0;
    height: 100%;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}

.b-intro_content {
    display: table-cell;
    vertical-align: middle;
    padding: 140px 0 40px;
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, .6);
}

.b-intro_content p {
    margin: 1.5em 0;
    font: 400 1em/1.35 'arial', sans-serif;
}

@media (min-width: 768px) {
    .b-intro_content {
        padding: 180px 15px 80px;
    }
}

.b-intro_container {
    position: relative;
}

@media (max-width: 767px) {
    .b-intro_container .e-frame {
        display: none;
    }
}

@media (min-width: 768px) {
    .b-intro_container {
        padding: 80px 0;
    }
}


/* * * * * *
 * * * * * * Intro header * * * * * */

.b-intro_header {
    margin-bottom: 48px;
}

.b-intro_header p {
    margin: 0 0 16px;
    font: 600 1.125em/1.3 'arial', sans-serif;
    letter-spacing: .075em;
    text-transform: uppercase;
}

.b-intro_header h1 {
    font: 700 2em/1.05 'arial', sans-serif;
    letter-spacing: .05em;
    text-transform: uppercase;
}

.b-intro_header h1 ~ p {
    margin: 16px 0 0;
}

@media (min-width: 480px) {
    .b-intro_header p {
        margin: 0 0 24px;
    }
    .b-intro_header h1 {
        font: 700 2.625em/1.05 'arial', sans-serif;
    }
    .b-intro_header h1 ~ p {
        margin: 24px 0 0;
    }
}


/* * * * * *
 * * * * * * Intro with fixed background * * * * * */

.b-intro__background {
    background-position: center center;
    background-repeat: no-repeat;
}

@media (min-width: 768px) {
    .m-desktop .b-intro__background {
        background-attachment: fixed;
    }
}


/* * * * * *
 * * * * * * Intro with video * * * * * */

.b-intro__video {
    position: relative;
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
}

.b-intro__video .b-intro_video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.m-touch .b-intro_video {
    display: none;
}

.m-desktop .b-intro_video__fixed {
    position: fixed;
    z-index: -100;
    top: 0;
    left: 50%;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.m-desktop .b-intro_video__fixed ~ .b-section__background {
    background: transparent !important;
}


/* * * * * *
 * * * * * * Intro with slider * * * * * */

.b-intro__slider {
    position: relative;
}

.b-intro__slider .b-intro_wrapper {
    background-position: center center;
    background-repeat: no-repeat;
}

.b-intro__slider .b-intro_content {
    padding: 140px 0 80px;
}

@media (min-width: 768px) {
    .b-intro__slider .b-intro_content {
        padding: 180px 15px 80px;
    }
}

.b-intro__slider .b-slider_arrow {
    position: absolute;
    top: 50%;
    text-decoration: none;
    cursor: pointer;
    -webkit-perspective: 500px;
    -moz-perspective: 500px;
    perspective: 500px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.b-intro__slider .b-slider_arrow:before {
    display: block;
    position: relative;
    z-index: 5;
    padding: 14px;
    width: 24px;
    height: 24px;
    font: normal 24px/1 'Ionicons', sans-serif;
    text-align: center;
    color: #fff;
    background: #333;
}

.b-intro__slider .b-slider_arrow:hover:before {
    background: #000;
}

.b-intro__slider .b-slider_arrow:after {
    display: none;
    position: absolute;
    z-index: 0;
    top: 0;
    bottom: 0;
    padding: 16px 22px;
    font: 900 1em/22px 'arial', sans-serif;
    letter-spacing: .05em;
    text-transform: uppercase;
    color: #000;
    background: #fff;
    cursor: pointer;
}

.m-desktop.m-csstransforms3d .b-intro__slider .b-slider_arrow:after {
    display: block;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
}

.m-desktop.m-csstransforms3d .b-intro__slider .b-slider_arrow:hover:after {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.b-intro__slider .b-slider_prev {
    left: 0;
}

.b-intro__slider .b-slider_prev:before {
    text-indent: -2px;
    content: '\f124';
}

.b-intro__slider .b-slider_prev:after {
    content: 'Prev';
    left: 100%;
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    transform: rotateY(90deg);
}

.b-intro__slider .b-slider_next {
    right: 0;
}

.b-intro__slider .b-slider_next:before {
    text-indent: 2px;
    content: '\f125';
}

.b-intro__slider .b-slider_next:after {
    content: 'Next';
    right: 100%;
    -webkit-transform-origin: right top;
    -moz-transform-origin: right top;
    transform-origin: right top;
    -webkit-transform: rotateY(-90deg);
    -moz-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
}

.b-intro__slider .b-slider_arrow:after,
.b-intro__slider .b-slider_arrow:before {
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
}

@media (max-width: 767px) {
    .b-intro__slider .b-slider_arrow:after {
        display: none;
        content: none;
    }
    .b-intro__slider .b-slider_arrow:before {
        padding: 8px;
    }
    .b-intro__slider .b-slider_prev {
        top: auto;
        bottom: 0;
        left: auto;
        right: 50%;
    }
    .b-intro__slider .b-slider_next {
        top: auto;
        bottom: 0;
        right: auto;
        left: 50%;
    }
}


/* --------------------
   -------------------- Sections :: Features -------------------- */

.b-features {
    padding: 20px 0 40px;
}

.b-features_item {
    margin: 20px auto 0;
    text-align: center;
}

@media (min-width: 768px) {
    .b-features {
        padding: 20px 0 60px;
    }
    .b-features_item {
        margin: 40px auto 0;
    }
}


/* * * * * *
 * * * * * * Intro summary icons * * * * * */

.b-features_item_icon {
    display: block;
    position: relative;
    margin: 0 auto 30px;
    width: 80px;
    height: 70px;
    font-size: 36px;
    text-indent: 2px;
    text-align: center;
}

.b-features_item_icon:before {
    display: block;
    width: 80px;
    height: 70px;
    line-height: 70px;
}

.b-features_item p {
    font: 400 1em/1.35 'arial', sans-serif;
}

.b-features_item p strong {
    font: 900 1em/1.25 'arial', sans-serif;
    letter-spacing: .05em;
    text-transform: uppercase;
}


/* --------------------
   -------------------- Sections :: Overview -------------------- */


/* * * * * *
 * * * * * * Box header * * * * * */

.b-overview_header {
    display: table;
    width: 100%;
    margin: 30px 0;
    border-collapse: collapse;
}

.b-overview_header_cell {
    display: table-cell;
    vertical-align: bottom;
    width: 80px;
}

.b-overview_header_cell ~ .b-overview_header_cell {
    padding: 5px 0 5px 20px;
    width: auto;
}

.b-overview_header_icon {
    display: block;
    position: relative;
    width: 80px;
    height: 70px;
    font-size: 36px;
    text-indent: 2px;
    text-align: center;
}

.b-overview_header_icon:before {
    display: block;
    width: 80px;
    height: 70px;
    line-height: 70px;
}

.b-overview_header h3 {
    font: 700 1em/1.25 'arial', sans-serif;
    letter-spacing: .05em;
    text-transform: uppercase;
}


/* * * * * *
 * * * * * * Box article * * * * * */

.b-overview_article {
    margin-bottom: 30px;
}


/* * * * * *
 * * * * * * Figures * * * * * */

.b-overview_figure {
    position: relative;
    padding: 55px 30px;
    max-width: 400px;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.b-overview_figure svg {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.b-overview_figure svg rect {
    fill: none;
    fill-rule: evenodd;
    stroke-width: 6;
    stroke-dasharray: 1300;
    stroke-dashoffset: 1300;
    -webkit-transition: all 2000ms ease;
    -moz-transition: all 2000ms ease;
    -o-transition: all 2000ms ease;
    transition: all 2000ms ease;
}

.b-overview_figure p {
    position: relative;
    z-index: 5;
    font: 700 1.375em/1.28 'arial', sans-serif;
    letter-spacing: .025em;
    text-transform: uppercase;
}

.b-overview_figure figcaption {
    position: relative;
    z-index: 5;
    margin-bottom: 10px;
    font: 900 .875em/1.25 'arial', sans-serif;
    letter-spacing: .05em;
    text-transform: uppercase;
}

@media (max-width: 767px) {
    .b-overview_figure {
        display: none !important;
    }
}

@media (min-width: 768px) {
    .b-overview_figure#overview-second-frame {
        left: 10px;
        top: -40px;
    }
    .b-overview_figure#overview-fifth-frame {}
}

@media (min-width: 970px) {
    .b-overview_figure#overview-second-frame {
        left: 50px;
        top: -110px;
    }
    .b-overview_figure#overview-fifth-frame {
        left: 25px;
        top: -50px;
    }
}


/* --------------------
   -------------------- Sections :: Counters -------------------- */

.b-counters .b-section__background_overlay {
    padding-top: 10px;
}

@media (min-width: 768px) {
    .b-counters .b-section__background_overlay {
        padding-top: 40px;
    }
}

.b-counters_item {
    margin-top: 30px;
    text-align: center;
    color: #fff;
}

.b-counters_item_digits {
    margin-bottom: 18px;
    font: 300 3.75em/1 'Oswald', sans-serif;
    text-transform: uppercase;
}

.b-counters_item h3 {
    position: relative;
    padding-bottom: 22px;
    font: 700 1em/1 'arial', sans-serif;
    text-transform: uppercase;
    letter-spacing: normal;
}

.b-counters_item p {
    margin-top: 18px;
    font: 400 1em/1.5 'arial', sans-serif;
}

.b-counters_item h3:after,
.b-counters_item h3:before {
    position: absolute;
    top: 100%;
    width: 65px;
    height: 1px;
    content: '';
}

.b-counters_item h3:after {
    margin-top: 2px;
    left: 50%;
    margin-left: -15px;
}

.b-counters_item h3:before {
    right: 50%;
    margin-right: -15px;
}

@media (min-width: 768px) {
    .b-counters_item h3 {
        font: 700 1.125em/1 'arial', sans-serif;
        letter-spacing: .1em;
    }
}


/* --------------------
   -------------------- Sections :: Schedule -------------------- */

.b-schedule {
    padding-bottom: 0;
}


/* * * * * *
 * * * * * * Download * * * * * */

.b-schedule_download {
    position: relative;
    margin-bottom: 60px;
    text-align: center;
}

.b-schedule_download_frame {
    display: inline-block;
    position: relative;
}

.b-schedule_download_btn {
    display: inline-block;
    position: relative;
    padding: 16px 30px;
    font: 700 1em/1 'arial', sans-serif;
    text-decoration: none;
    white-space: nowrap;
    color: inherit !important;
}

.b-schedule_download_btn:before {
    vertical-align: baseline;
    position: relative;
    top: 1px;
    font-size: 20px;
    margin-right: 7px;
}

.b-schedule_download_btn small {
    font: 400 .8125em/1 'arial', sans-serif;
    color: #aaa;
}


/* * * * * *
 * * * * * * Days nav * * * * * */

.b-schedule_tabs {
    margin-bottom: 20px;
}

.b-schedule_tabs_btn {
    display: block;
    position: relative;
    padding: 14px 20px;
    margin-bottom: 10px;
    text-decoration: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.b-schedule_tabs_btn time {
    margin-left: 16px;
    font: 300 .875em/1 'arial', sans-serif;
}

.b-schedule_tabs_btn strong {
    font: 700 1.25em/1 'arial', sans-serif;
}

.b-schedule_tabs_btn.current,
.b-schedule_tabs_btn.current * {
    color: #fff;
}

@media (min-width: 768px) {
    .b-schedule_tabs__offset {
        margin-top: 92px;
    }
    .b-schedule_tabs_btn {
        -webkit-border-radius: 4px 0 0 4px;
        -moz-border-radius: 4px 0 0 4px;
        border-radius: 4px 0 0 4px;
    }
    .b-schedule_tabs_btn:nth-child(3n+2) {
        left: 15px;
        margin-left: 10px;
    }
    .b-schedule_tabs_btn:nth-child(3n+3) {
        margin-left: 10px;
    }
}


/* * * * * *
 * * * * * * Device * * * * * */

@media (min-width: 480px) {
    .b-schedule_device {
        padding: 88px 20px 0;
        -webkit-border-radius: 48px 48px 0 0;
        -moz-border-radius: 48px 48px 0 0;
        border-radius: 48px 48px 0 0;
    }
    .b-schedule_device_screen {
        overflow: hidden;
        padding: 30px 30px 40px;
        min-height: 200px;
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        border-radius: 1px;
    }
    .b-schedule_device,
    .b-schedule_device_screen {
        position: relative;
        border-bottom: none;
    }
    .b-schedule_device:before {
        position: absolute;
        left: 50%;
        top: 38px;
        margin-left: -5px;
        width: 10px;
        height: 10px;
        background: #17171b;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        content: '';
    }
}

@media (min-width: 768px) and (max-width: 969px) {
    .b-schedule_device {
        margin: 0 -15px 0 -40px;
        padding-right: 0;
        border-right: none;
        -webkit-border-radius: 48px 0 0 0;
        -moz-border-radius: 48px 0 0 0;
        border-radius: 48px 0 0 0;
    }
    .b-schedule_device_screen {
        padding-right: 15px;
        border-right: none;
        -webkit-border-radius: 1px 0 0 1px;
        -moz-border-radius: 1px 0 0 1px;
        border-radius: 1px 0 0 1px;
    }
    .b-schedule_device:before {
        margin-left: 20px;
    }
}

@media (min-width: 970px) {
    .b-schedule_device {
        margin: 0 0 0 -30px;
    }
}


/* * * * * *
 * * * * * * Time line * * * * * */

.b-schedule_timeLine {
    position: relative;
    padding: 20px 0 30px 50px;
    margin-left: 28px;
    border-left-width: 2px;
    border-left-style: solid;
    -webkit-transition: border 400ms;
    -moz-transition: border 400ms;
    transition: border 400ms;
}

.b-schedule_timeLine:before {
    position: absolute;
    left: -5px;
    top: 0;
    width: 8px;
    height: 8px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    content: '';
    -webkit-transition: background 400ms;
    -moz-transition: background 400ms;
    transition: background 400ms;
}

@media (max-width: 768px) {
    .b-schedule_timeLine {
        padding: 20px 0 60px 50px;
    }
}


/* * * * * *
 * * * * * * Speech details * * * * * */

.b-schedule_speech {
    position: relative;
}

.b-schedule_speech ~ .b-schedule_speech {
    margin-top: 30px;
}

.b-schedule_speech_header {
    position: relative;
    padding: 3px 0;
    text-indent: 1px;
}

.b-schedule_speech_header_pic {
    position: absolute;
    left: -77px;
    top: 0;
    width: 52px;
    height: 52px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: border 400ms;
    -moz-transition: border 400ms;
    transition: border 400ms;
}

.b-schedule_speech_header_dummy {
    position: absolute;
    left: -77px;
    top: 0;
    width: 52px;
    height: 52px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.b-schedule_speech_header_dummy:before {
    display: block;
    width: 52px;
    height: 52px;
    line-height: 50px;
    text-align: center;
    text-indent: 3px;
    font-size: 28px;
    color: #fff;
}

.b-schedule_speech_header_name,
.b-schedule_speech_header_time {
    margin-right: 12px;
    font: 300 1em/1 'arial', sans-serif;
    white-space: nowrap;
}

.b-schedule_speech_header_name:before,
.b-schedule_speech_header_time:before {
    vertical-align: baseline;
    font-size: 22px;
    margin-right: 7px;
}

@media (max-width: 768px) {
    .b-schedule_speech_header {
        font-size: .875em;
    }
}


/* * * * * *
 * * * * * * Speech: description * * * * * */

.b-schedule_speech_announcement {
    padding-top: 10px;
}

.b-schedule_speech_announcement p ~ p {
    margin-top: .75em;
}

@media (min-width: 480px) {
    .b-schedule_speech_announcement {
        padding-top: 20px;
    }
}


/* * * * * *
 * * * * * * Speech: caption * * * * * */

.b-schedule_speech_toggle {
    position: relative;
    margin-top: 3px;
    padding-right: 32px;
    font: 900 1em/1.25 'arial', sans-serif;
    text-transform: uppercase;
    cursor: default;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    transition: all 400ms;
}

.js-spoiler .b-schedule_speech_toggle {
    cursor: pointer;
}


/* * * * * * *
 * * * * * * Speech: caption arrow * * * * * */

.b-schedule_speech_arrow {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -3px;
    width: 28px;
    height: 28px;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    transition: all 400ms;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

.b-schedule_speech_arrow:before {
    display: block;
    width: 28px;
    height: 28px;
    font: normal 14px/30px 'Ionicons', sans-serif;
    text-indent: 1px;
    text-decoration: none;
    text-align: center;
    content: '\f103';
}

.b-schedule_speech_arrow .e-frame:after,
.b-schedule_speech_arrow .e-frame:before {
    width: 8px;
    height: 8px;
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
}

.opened .b-schedule_speech_arrow {
    color: #aaa;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    transform: rotate(180deg);
}


/* * * * * *
 * * * * * * Schedule day slider * * * * * */

.b-schedule_slider .b-slider_control_captions ~ .b-slider .b-slider_viewPort {
    height: auto !important;
}

.b-schedule_slider .b-slider_control_captions {
    margin-bottom: 20px;
}

.b-schedule_slider .b-slider_control_captions .b-slider_bullet {
    display: block;
}

.b-schedule_slider .b-slider_control_captions .b-slider_bullet a {
    display: block;
    position: relative;
    padding: 10px 20px;
    font: 700 1em/1 'arial', sans-serif;
    text-decoration: none;
    color: #999;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.b-schedule_slider .b-slider_control_captions .b-slider_bullet a:hover {
    color: #333;
    background: #f0f0f0;
}

@media (min-width: 480px) {
    .b-schedule_slider .b-slider_control_captions {
        text-align: center;
    }
    .b-schedule_slider .b-slider_control_captions .b-slider_bullet {
        display: inline-block;
        vertical-align: bottom;
        margin-left: 7px;
    }
    .b-schedule_slider .b-slider_control_captions .b-slider_bullet a {
        padding: 14px 20px;
        color: #333;
        background: #f9f9f9;
    }
    .b-schedule_slider .b-slider_control_captions .b-slider_bullet a:hover {
        color: #666;
        background: #f0f0f0;
    }
    .b-schedule_slider .b-slider_control_captions .b-slider_bullet a.b-slider_active {
        color: #fff !important;
    }
}

@media (min-width: 768px) {
    .b-schedule_slider .b-slider_control_captions {
        position: relative;
        z-index: 10;
        text-align: right;
    }
    .b-schedule_slider .b-slider_control_captions ~ .b-slider {
        margin-top: -40px;
    }
    .b-schedule_slider .b-slider_control_captions ~ .b-slider .b-schedule_timeLine {
        padding-top: 40px;
        padding-right: 5px;
    }
    .b-schedule_slider .b-slider_control_captions ~ .b-slider .b-wysiwyg {
        margin-top: 40px;
    }
}


/* * * * * *
 * * * * * * Schedule day tabs * * * * * */

.b-schedule_day_nav {
    margin-bottom: 10px;
}

.b-schedule_day_nav a {
    display: block;
    position: relative;
    padding: 10px 20px;
    font: 700 1em/1 'arial', sans-serif;
    text-decoration: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

@media (min-width: 480px) {
    .b-schedule_day_nav {
        text-align: center;
    }
    .b-schedule_day_nav a {
        display: inline-block;
        vertical-align: bottom;
        margin-left: 7px;
        margin-bottom: 10px;
        padding: 14px 20px;
    }
    .b-schedule_day_nav a.current {
        color: #fff !important;
    }
}

@media (min-width: 768px) {
    .b-schedule_day_nav {
        position: relative;
        z-index: 10;
        text-align: right;
    }
    .b-schedule_day_nav ~ .js-tabs-wrapper {
        margin-top: -40px;
    }
    .b-schedule_day_nav ~ .js-tabs-wrapper .b-schedule_timeLine {
        padding-top: 40px;
        padding-right: 5px;
    }
    .b-schedule_day_nav ~ .js-tabs-wrapper .b-wysiwyg {
        margin-top: 40px;
    }
}


/* --------------------
   -------------------- Sections :: Subscribe -------------------- */

.b-subscribe .b-section_header {
    margin-bottom: 40px;
}

.b-subscribe .b-form_box {
    margin-bottom: 0;
}

.b-subscribe_btn {
    position: absolute;
    z-index: 5;
    top: 0;
    right: -1px;
    width: 42px;
    height: 42px;
    padding: 0;
    font-size: 22px;
    line-height: 40px;
    text-align: center;
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.b-subscribe_field {
    margin-bottom: 20px;
}

.b-subscribe_btn.e-btn_request:after {
    display: block;
    position: relative;
    width: 18px;
    height: 18px;
    margin: 6px auto;
    content: '';
    border-top: 4px solid rgba(255, 255, 255, 0.2);
    border-right: 4px solid rgba(255, 255, 255, 0.2);
    border-bottom: 4px solid rgba(255, 255, 255, 0.2);
    border-left: 4px solid #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: spin 1.1s infinite linear;
    -moz-animation: spin 1.1s infinite linear;
    animation: spin 1.1s infinite linear;
}

.b-subscribe_btn.e-btn_request:before {
    content: none;
}


/* --------------------
   -------------------- Sections :: Speakers -------------------- */

.b-speakers {
    padding-bottom: 40px;
}

.b-speakers_list {
    display: flex;
    flex-wrap: wrap;
    letter-spacing: -5px;
}

.b-speakers_cell {
    display: inline-block;
    vertical-align: top;
    float: none;
    letter-spacing: normal;
}

.b-speakers_view {
    position: relative;
    margin: 0 auto 40px;
    max-width: 280px;
    letter-spacing: .025em;
    text-align: center;
}

.b-speakers_view_pic {
    display: block;
    margin: 0 auto 20px;
    width: 140px;
    height: 140px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.b-speakers_view h3 {
    font: 900 1.125em/1.5 'arial', sans-serif;
}

.b-speakers_view h3 span {
    display: block;
    font: 400 .77777777777778em 'arial', sans-serif;
}

.b-speakers_view p {
    margin-top: .75em;
    min-height: 63px;
    font: 300 1em/1.3125 'arial', sans-serif;
}

.b-speakers_view_accounts {
    margin-top: 20px;
    text-align: center;
    white-space: nowrap;
    cursor: default;
}

.b-speakers_view_accounts a {
    display: inline-block;
    vertical-align: middle;
    padding: 1px 4px;
    height: 22px;
    font-size: 22px;
    line-height: 22px;
}

.b-speakers_view_accounts a:hover {
    color: inherit;
}


/* * * * * *
 * * * * * * Show effect * * * * * */

// .m-csstransitions .b-section__wp .b-speakers_cell .b-speakers_view {
//     // fixing the opacity:  issue on mobile.
//     opacity: 1;
//     -webkit-transform: scale(.25, .25);
//     -moz-transform: scale(.25, .25);
//     transform: scale(.25, .25);
//     -webkit-transition: all 600ms;
//     -moz-transition: all 600ms;
//     transition: all 600ms;
// }

// .m-csstransitions .b-section__wp.b-section__show .b-speakers_cell .b-speakers_view {
//     opacity: 1;
//     -webkit-transform: scale(1, 1);
//     -moz-transform: scale(1, 1);
//     transform: scale(1, 1);
// }

// .m-csstransitions .b-section__wp .b-speakers_cell:nth-child(2) .b-speakers_view {
//     -webkit-transition-delay: 200ms;
//     -moz-transition-delay: 200ms;
//     transition-delay: 200ms;
// }

// .m-csstransitions .b-section__wp .b-speakers_cell:nth-child(3) .b-speakers_view {
//     -webkit-transition-delay: 300ms;
//     -moz-transition-delay: 300ms;
//     transition-delay: 300ms;
// }

// .m-csstransitions .b-section__wp .b-speakers_cell:nth-child(4) .b-speakers_view {
//     -webkit-transition-delay: 400ms;
//     -moz-transition-delay: 400ms;
//     transition-delay: 400ms;
// }

// .m-csstransitions .b-section__wp .b-speakers_cell:nth-child(5) .b-speakers_view {
//     -webkit-transition-delay: 500ms;
//     -moz-transition-delay: 500ms;
//     transition-delay: 500ms;
// }

// .m-csstransitions .b-section__wp .b-speakers_cell:nth-child(6),
// .m-csstransitions .b-section__wp .b-speakers_cell:nth-child(6) ~ .b-speakers_cell .b-speakers_view {
//     -webkit-transition-delay: 600ms;
//     -moz-transition-delay: 600ms;
//     transition-delay: 600ms;
// }


/* --------------------
   -------------------- Sections :: Pricing plans -------------------- */

.b-pricing {
    padding-bottom: 50px;
}

.b-pricing_plan {
    position: relative;
    max-width: 300px;
    margin: 0 auto 30px;
}


/* * * * * *
 * * * * * * Pricing deck * * * * * */

.b-pricing_plan_deck {
    position: relative;
    overflow: hidden;
    padding: 40px 20px 74px;
    font: 400 1em/1.3125 'arial', sans-serif;
    text-align: center;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    transition: all 400ms;
}

.b-pricing_plan_deck_caption {
    margin-bottom: 28px;
    font: 600 1.125em/1.35 'arial', sans-serif;
    text-transform: uppercase;
}

.b-pricing_plan_deck_ribbon {
    position: absolute;
    left: -29px;
    top: 22px;
    width: 130px;
    height: 26px;
    overflow: hidden;
    visibility: hidden;
    font: 900 .875em/26px 'arial', sans-serif;
    text-transform: uppercase;
}

.m-csstransforms .b-pricing_plan_deck_ribbon {
    visibility: visible;
    -webkit-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}


/* * * * * *
 * * * * * * Pricing deck content * * * * * */

.b-pricing_plan_price {
    margin-bottom: 56px;
    white-space: nowrap;
}

.b-pricing_plan_price_validity {
    font: 300 1.125em/1 'arial', sans-serif;
}

.b-pricing_plan_price_currency {
    vertical-align: top;
    margin-right: 3px;
    font: 400 1.5em/1 'arial', sans-serif;
}

.b-pricing_plan_price strong {
    font: 300 3em/1 'Oswald', sans-serif;
}

.b-pricing_plan_features {
    overflow: hidden;
    height: 155px;
}

.b-pricing_plan_features p {
    margin: 0 0 10px;
}

.b-pricing_plan_purchase {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 16px 20px;
    font: 900 1.125em/1 'arial', sans-serif;
    text-transform: uppercase;
    color: #fff;
    background: #333;
    -webkit-border-radius: 0 0 2px 2px;
    -moz-border-radius: 0 0 2px 2px;
    border-radius: 0 0 2px 2px;
}


/* * * * * *
 * * * * * * Change plans: default * * * * * */

.b-pricing_plan .b-pricing_plan_deck__back {
    display: none;
    visibility: hidden;
}

.b-pricing_plan .b-pricing_plan_deck__front {
    display: block;
    visibility: visible;
}

.b-pricing_plan__switched .b-pricing_plan_deck__back {
    display: block;
    visibility: visible;
}

.b-pricing_plan__switched .b-pricing_plan_deck__front {
    display: none;
    visibility: hidden;
}


/* * * * * *
 * * * * * * Change plans: 3d flip * * * * * */

.m-csstransforms3d .b-pricing_plan {
    height: 425px;
    -webkit-perspective: 1500px;
    -moz-perspective: 1500px;
    perspective: 1500px;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.m-csstransforms3d .b-pricing_plan_deck {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    visibility: visible;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: center top;
    -moz-transform-origin: center top;
    transform-origin: center top;
}

.m-csstransforms3d .b-pricing_plan_deck__back {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.m-csstransforms3d .b-pricing_plan_deck__front {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.m-csstransforms3d .b-pricing_plan__switched .b-pricing_plan_deck__back {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg);
}

.m-csstransforms3d .b-pricing_plan__switched .b-pricing_plan_deck__front {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}


/* * * * * *
 * * * * * * Plans switcher * * * * * */

.b-pricing_switcher {
    margin-bottom: 60px;
}

.b-pricing_switcher_box {
    display: block;
    position: relative;
    width: 136px;
    height: 36px;
    margin: 0 auto;
    padding: 2px;
    color: #fff;
    overflow: hidden;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    border-radius: 18px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

.b-pricing_switcher_box input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
}

.b-pricing_switcher_box_roll {
    display: block;
    position: relative;
    white-space: nowrap;
    letter-spacing: -5px;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}

.b-pricing_switcher_box_roll:before {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    background: #fff;
    -webkit-box-shadow: 0 -2px 1px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 -2px 1px rgba(0, 0, 0, .1);
    box-shadow: 0 -2px 1px rgba(0, 0, 0, .1);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    content: '';
}

.b-pricing_switcher_box_roll span {
    display: inline-block;
    position: relative;
    width: 132px;
    padding: 0 32px 0 0;
    font: 900 .875em/34px 'arial', sans-serif;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.b-pricing_switcher_box_roll span + span {
    width: 100px;
    padding: 0;
}

.b-pricing_switcher_box input:checked ~ .b-pricing_switcher_box_roll {
    -webkit-transform: translate(-100px, 0);
    -moz-transform: translate(-100px, 0);
    -ms-transform: translate(-100px, 0);
    transform: translate(-100px, 0);
}

.b-pricing_switcher_box input:checked ~ .b-pricing_switcher_box_roll:before {
    -webkit-box-shadow: 0 2px 1px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 2px 1px rgba(0, 0, 0, .1);
    box-shadow: 0 2px 1px rgba(0, 0, 0, .1);
}

.b-pricing_switcher_box,
.b-pricing_switcher_box_roll,
.b-pricing_switcher_box_roll:before {
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    transition: all 300ms;
}


/* --------------------
   -------------------- Sections :: FAQ -------------------- */

.b-faq {
    overflow: hidden;
}


/* * * * * *
 * * * * * * Device * * * * * */

.b-faq_device_screen {
    position: relative;
}

@media (min-width: 480px) {
    .b-faq_device {
        position: relative;
        z-index: 30;
        padding: 12px 78px;
        width: 454px;
        height: 258px;
        margin: 0 auto;
        -webkit-border-radius: 36px;
        -moz-border-radius: 36px;
        border-radius: 36px;
    }
    .b-faq_device:after {
        position: absolute;
        z-index: 0;
        right: 50px;
        top: -4px;
        bottom: -4px;
        width: 8px;
        content: '';
    }
    .b-faq_device:before {
        position: absolute;
        z-index: 0;
        left: 45px;
        top: -4px;
        bottom: -4px;
        width: 8px;
        content: '';
    }
    .b-faq_device_home {
        position: absolute;
        z-index: 5;
        left: 12px;
        top: 50%;
        margin-top: -27px;
        width: 54px;
        height: 54px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .b-faq_device_cam {
        position: absolute;
        right: 26px;
        top: 50%;
        margin-top: -5px;
        width: 10px;
        height: 10px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        content: '';
    }
    .b-faq_device_speaker {
        position: absolute;
        z-index: 5;
        right: 55px;
        top: 50%;
        margin-top: -27px;
        width: 0;
        height: 54px;
    }
    .b-faq_device_speaker:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 5px;
        height: 5px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        content: '';
    }
    .b-faq_device_speaker:after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 5px;
        height: 44px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        content: '';
    }
    .b-faq_device_power {
        position: absolute;
        right: -6px;
        top: 200px;
        width: 4px;
        height: 46px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
    }
    .b-faq_device_switcher {
        position: absolute;
        right: 78px;
        top: -6px;
        width: 30px;
        height: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
    }
    .b-faq_device_volume_up {
        position: absolute;
        right: 138px;
        top: -6px;
        width: 20px;
        height: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
    }
    .b-faq_device_volume_down {
        position: absolute;
        right: 178px;
        top: -6px;
        width: 20px;
        height: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
    }
    .b-faq_device_head {
        position: absolute;
        z-index: 5;
        right: -4px;
        top: 0;
        bottom: 0;
        width: 78px;
        -webkit-border-radius: 0 36px 36px 0;
        -moz-border-radius: 0 36px 36px 0;
        border-radius: 0 36px 36px 0;
    }
    .b-faq_device_footer {
        position: absolute;
        z-index: 5;
        left: -4px;
        top: 0;
        bottom: 0;
        width: 78px;
        -webkit-border-radius: 36px 0 0 36px;
        -moz-border-radius: 36px 0 0 36px;
        ;
        border-radius: 36px 0 0 36px;
    }
    .b-faq_device_screen {
        width: 450px;
        height: 254px;
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        border-radius: 1px;
    }
    .b-faq_device_screen .e-frame__top:after {
        left: 20px;
        top: 20px;
    }
    .b-faq_device_screen .e-frame__top:before {
        right: 20px;
        top: 20px;
    }
    .b-faq_device_screen .e-frame__bottom:after {
        left: 20px;
        bottom: 20px;
    }
    .b-faq_device_screen .e-frame__bottom:before {
        right: 20px;
        bottom: 20px;
    }
}

@media (min-width: 480px) and (max-width: 767px) {
    .b-faq_device {
        left: 50%;
        margin-left: -305px;
    }
}


/* * * * * *
 * * * * * * Slider * * * * * */

.b-faq_slider,
.b-faq_slider .b-slider {
    height: 100%;
}

.b-faq_slider .b-slider_viewPort {
    height: 100% !important;
}

@media (min-width: 970px) {
    .b-faq_slider .b-slider_viewPort {
        overflow: visible !important;
    }
    .b-faq_slider_item {
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all 400ms;
        -moz-transition: all 400ms;
        transition: all 400ms;
    }
    .b-faq_slider_item.b-slider_current {
        opacity: 1;
        visibility: visible;
    }
    .b-faq_slider_item.b-slider_current + .b-faq_slider_item {
        opacity: .25;
        visibility: visible;
    }
    .b-faq_slider_item.b-slider_current + .b-faq_slider_item:before {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        content: '';
    }
}

.b-faq_device {
    margin-bottom: 60px;
}

.b-faq_slider .b-slider_control {
    position: absolute;
    left: 0;
    top: 100%;
    right: 0;
    bottom: auto;
    margin-top: 20px;
}

@media (min-width: 480px) {
    .b-faq_device {
        margin-bottom: 50px;
    }
    .b-faq_slider .b-slider_control {
        position: absolute;
        left: 0;
        top: 100%;
        right: 0;
        bottom: auto;
        margin-top: 40px;
    }
}


/* * * * * *
 * * * * * * Question * * * * * */

.b-faq_question {
    position: relative;
    overflow: hidden;
    border: 20px solid transparent;
    height: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.b-faq_question_wrapper {
    display: table;
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.b-faq_question_wrapper_inner {
    display: table-cell;
    vertical-align: middle;
    padding-top: 15px;
    text-align: center;
}


/* * * * * *
 * * * * * * Button * * * * * */

.b-faq_btn {
    position: relative;
    z-index: 30;
    margin-top: 40px;
    text-align: center;
}

@media (min-width: 970px) {
    .b-faq_btn {
        margin: 0;
        padding: 100px 10px;
    }
}


/* --------------------
   -------------------- Sections :: SignUp -------------------- */


/* * * * * *
 * * * * * * Countdown * * * * * */

.b-signUp_countdown_started,
.b-signUp_countdown_caption,
.b-signUp_countdown_dash_title {
    color: #fff;
}


/* * * * * *
 * * * * * * Form * * * * * */

.b-signUp .b-form_box {
    margin: 0 -5px 20px;
}

.b-signUp .b-form_bottom {
    margin-left: -5px;
    margin-right: -5px;
}

@media (max-width: 480px) {
    .b-signUp_form_bottom .e-btn {
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 767px) {
    .b-signUp_countdown {
        margin: 20px 0 30px;
    }
}

@media (min-width: 992px) {
    .b-signUp_countdown {
        margin: -4px 0 0;
    }
    .b-signUp_form_bottom {
        margin-top: 50px;
    }
}


/* --------------------
   -------------------- Sections :: Partners -------------------- */

.b-partners_logotypes_item {
    display: table;
    width: 100%;
    height: 48px;
    border-spacing: 0;
    table-layout: fixed;
}

.b-partners_logotypes_item_inner {
    display: table-cell;
    vertical-align: middle;
    padding: 0 10px;
    -webkit-transition: all 400ms;
    -moz-transition: all 400ms;
    -o-transition: all 400ms;
    transition: all 400ms;
}

.b-partners_logotypes_item_inner img {
    display: block;
    margin: 0 auto;
    max-height: 48px;
    max-width: 100%;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: visible;
    -moz-backface-visibility: visible;
    backface-visibility: visible;
}

.b-partners_logotypes_item_inner:hover {
    opacity: .75;
}


/* --------------------
   -------------------- Sections :: Contacts -------------------- */

.b-contacts {
    position: relative;
    overflow: hidden;
}

.b-contacts_inner {
    position: relative;
    z-index: 25;
    padding-bottom: 300px;
    pointer-events: none;
}

@media (min-width: 480px) {
    .b-contacts_inner {
        padding-bottom: 200px;
    }
}

@media (min-width: 768px) {
    .b-contacts {
        padding: 60px 0;
    }
    .b-contacts_inner {
        padding-bottom: 0;
        // min-height: 520px;
        height: auto;
    }
}


/* * * * * *
 * * * * * * Details * * * * * */

.b-contacts_details {
    padding: 40px 10px;
    pointer-events: auto;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.b-contacts_details_header {
    margin-bottom: 32px;
}

.b-contacts_details_social {
    margin-bottom: 42px;
    text-align: center;
    white-space: nowrap;
    cursor: default;
}

.b-contacts_details_social a {
    display: inline-block;
    vertical-align: middle;
    padding: 1px 4px;
    height: 22px;
    font-size: 22px;
    line-height: 22px;
}

.b-contacts_details_social a:hover {
    color: inherit;
}

.b-contacts_details_list {
    list-style: none;
    padding-left: 20px;
    margin: 0;
    font: 300 1.125em/1.35 'arial', sans-serif;
    text-align: left;
}

.b-contacts_details_list li ~ li {
    margin-top: 10px;
}

.b-contacts_details_list a {
    text-decoration: none;
    color: inherit;
}


/* * * * * *
 * * * * * * Map * * * * * */

.b-contacts_map {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #f5f5f5;
}

.b-contacts_map_marker {
    height: 48px;
    width: 48px;
    text-align: center;
    text-indent: 2px;
}

.b-contacts_map_marker:before {
    width: auto;
    font: normal 48px/1 'Ionicons', sans-serif;
    text-decoration: none;
    text-transform: none;
    content: '\f2e9';
}


/* * * * * *
 * * * * * * Show effect * * * * * */

.m-desktop.m-csstransitions .b-section__wp .b-contacts_details {
    -webkit-transform: translate(0, 150%);
    -moz-transform: translate(0, 150%);
    transform: translate(0, 150%);
    -webkit-transition: opacity 400ms, -webkit-transform 600ms;
    -moz-transition: opacity 400ms, -moz-transform 400ms;
    transition: opacity 400ms, transform 400ms;
}

.m-desktop.m-csstransitions .b-section__wp.b-section__show .b-contacts_details {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
}


/* --------------------
   -------------------- Animations -------------------- */


/* * * * * *
 * * * * * * Glow pulse * * * * * */

.a-pulse {
    -webkit-animation: pulse 1800ms infinite linear;
    -moz-animation: pulse 1800ms infinite linear;
    -o-animation: pulse 1800ms infinite linear;
    animation: pulse 1800ms infinite linear;
}

@-webkit-keyframes pulse {
    0%,
    100% {
        -webkit-transform: scale(1.0);
    }
    40% {
        -webkit-transform: scale(0.75);
    }
    75% {
        -webkit-transform: scale(1.1);
    }
}

@-moz-keyframes pulse {
    0%,
    100% {
        -moz-transform: scale(1.0);
    }
    40% {
        -moz-transform: scale(0.75);
    }
    75% {
        -moz-transform: scale(1.1);
    }
}

@keyframes pulse {
    0%,
    100% {
        transform: scale(1.0);
    }
    40% {
        transform: scale(0.75);
    }
    75% {
        transform: scale(1.1);
    }
}


/* * * * * *
 * * * * * * 3D rotate * * * * * */

@-webkit-keyframes rotate-one {
    0% {
        -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@-moz-keyframes rotate-one {
    0% {
        -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        -moz-transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@keyframes rotate-one {
    0% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
    }
}

@-webkit-keyframes rotate-two {
    0% {
        -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@-moz-keyframes rotate-two {
    0% {
        -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        -moz-transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@keyframes rotate-two {
    0% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
    }
}

@-webkit-keyframes rotate-three {
    0% {
        -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

@-moz-keyframes rotate-three {
    0% {
        -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        -moz-transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}

@keyframes rotate-three {
    0% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
    }
    100% {
        transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
    }
}


/* * * * * *
 * * * * * * Spin * * * * * */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
