/*
 *  Lato Font by Łukasz Dziedzic - http://www.latofonts.com/team/
 *  License - http://scripts.sil.org/cms/scripts/page.php?site_id=nrsi&id=OFL (SIL Open Font License, 1.1)
 */

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Light.eot');
    src: url('../fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Lato-Light.woff') format('woff'),
         url('../fonts/Lato-Light.ttf') format('truetype'),
         url('../fonts/Lato-Light.svg?v=4.2.0#Lato') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Regular.eot');
    src: url('Lato-Regular.eot?#iefix') format('embedded-opentype'),
         url('Lato-Regular.woff') format('woff'),
         url('Lato-Regular.ttf') format('truetype'),
         url('Lato-Regular.svg?v=4.2.0#Lato') format('svg');
    font-weight: 400;
    font-style: normal;
}

/*
@font-face {
    font-family: 'Lato';
    src: url('Lato-Medium.eot');
    src: url('Lato-Medium.eot?#iefix') format('embedded-opentype'),
         url('Lato-Medium.woff') format('woff'),
         url('Lato-Medium.ttf') format('truetype'),
         url('Lato-Medium.svg?v=4.2.0#Lato') format('svg');
    font-weight: 500;
    font-style: normal;
}
*/

@font-face {
    font-family: 'Lato';
    src: url('Lato-Semibold.eot');
    src: url('Lato-Semibold.eot?#iefix') format('embedded-opentype'),
         url('Lato-Semibold.woff') format('woff'),
         url('Lato-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Bold.eot');
    src: url('Lato-Bold.eot?#iefix') format('embedded-opentype'),
         url('Lato-Bold.woff') format('woff'),
         url('Lato-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

/*
@font-face {
    font-family: 'Lato';
    src: url('Lato-Heavy.eot');
    src: url('Lato-Heavy.eot?#iefix') format('embedded-opentype'),
         url('Lato-Heavy.woff') format('woff'),
         url('Lato-Heavy.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
*/

@font-face {
    font-family: 'Lato';
    src: url('Lato-Black.eot');
    src: url('Lato-Black.eot?#iefix') format('embedded-opentype'),
         url('Lato-Black.woff') format('woff'),
         url('Lato-Black.ttf') format('truetype'),
         url('Lato-Black.svg?v=4.2.0#Lato') format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-LightItalic.eot');
    src: url('Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
         url('Lato-LightItalic.woff') format('woff'),
         url('Lato-LightItalic.ttf') format('truetype'),
         url('Lato-LightItalic.svg?v=4.2.0#Lato') format('svg');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Italic.eot');
    src: url('Lato-Italic.eot?#iefix') format('embedded-opentype'),
         url('Lato-Italic.woff') format('woff'),
         url('Lato-Italic.ttf') format('truetype'),
         url('Lato-Italic.svg?v=4.2.0#Lato') format('svg');
    font-weight: 400;
    font-style: italic;
}

/*
@font-face {
    font-family: 'Lato';
    src: url('Lato-MediumItalic.eot');
    src: url('Lato-MediumItalic.eot?#iefix') format('embedded-opentype'),
         url('Lato-MediumItalic.woff') format('woff'),
         url('Lato-MediumItalic.ttf') format('truetype'),
         url('Lato-MediumItalic.svg?v=4.2.0#Lato') format('svg');
    font-weight: 500;
    font-style: italic;
}
*/

@font-face {
    font-family: 'Lato';
    src: url('Lato-Semibolditalic.eot');
    src: url('Lato-Semibolditalic.eot?#iefix') format('embedded-opentype'),
         url('Lato-Semibolditalic.woff') format('woff'),
         url('Lato-Semibolditalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

/*
@font-face {
    font-family: 'Lato';
    src: url('Lato-BoldItalic.eot');
    src: url('Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('Lato-BoldItalic.woff') format('woff'),
         url('Lato-BoldItalic.ttf') format('truetype'),
         url('Lato-BoldItalic.svg?v=4.2.0#Lato') format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-HeavyItalic.eot');
    src: url('Lato-HeavyItalic.eot?#iefix') format('embedded-opentype'),
         url('Lato-HeavyItalic.woff') format('woff'),
         url('Lato-HeavyItalic.ttf') format('truetype'),
         url('Lato-HeavyItalic.svg?v=4.2.0#Lato') format('svg');
    font-weight: 800;
    font-style: italic;
}
*/

@font-face {
    font-family: 'Lato';
    src: url('Lato-BlackItalic.eot');
    src: url('Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
         url('Lato-BlackItalic.woff') format('woff'),
         url('Lato-BlackItalic.ttf') format('truetype'),
         url('Lato-BlackItalic.svg?v=4.2.0#Lato') format('svg');
    font-weight: 900;
    font-style: italic;
}
