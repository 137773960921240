/* -------------------- Table of contents --------------------

1. Global
2. Forms
3. Typographic
4. Blocks / elements
5. Header
6. Sections
    6.1. Features
    6.2. Overview
    6.3. Counters
    6.4. Schedule
    6.5. Subscribe
    6.6. Speakers
    6.7. Pricing plans
    6.8. FAQ
    6.9. Contacts
7. Plugins styles

----------------------- Table of content -------------------- */

/* --------------------
   -------------------- Global -------------------- */
a { color: #31bfc3; }
a:hover { color: inherit; }

.m-colorized { color: #31bfc3 !important; }

/* * * * * *
 * * * * * * Buttons * * * * * */
.e-btn_solid { color: #fff; background-color: #31bfc3; }
.e-btn_solid:hover { background-color: #2ba9ad; } /* Brightness - 8% */

.e-btn_outline { color: #31bfc3 !important; border-color: #31bfc3; background: rgba(49, 191, 195, .1); }
.e-btn_outline:hover { border-color: #2ba9ad; background: rgba(49, 191, 195, .2); } /* Brightness - 8% */

.e-btn_outline_white:hover { background: rgba(49, 191, 195, .1); border-color: #31bfc3; }

.e-btn_smooth:after { color: #31bfc3; }


/* --------------------
   -------------------- Forms -------------------- */
/* * * * * *
 * * * * * * Form notifications * * * * * *
                                * * * * * */
.b-form_message_balloon__success { color: #fff; background: #31bfc3; }
.b-form_message_balloon__error { color: #fff; background: #ff4d46; }

/* * * * * *
 * * * * * * Fields with icons * * * * * *
                               * * * * * */
.b-form_box_field.i-icon:before { color: #31bfc3; }

/* * * * * *
 * * * * * * Elements focus * * * * * *
                            * * * * * */
.b-form_box_field input:focus,
.b-form_box_field textarea:focus,

.b-form_box_field .selector.focus { border-color: #31bfc3; }


/* --------------------
   -------------------- Typographic -------------------- */
.b-wysiwyg a { color: #31bfc3; }
.b-wysiwyg blockquote { border-left-color: #31bfc3 !important; }

.b-wysiwyg li:before { color: #31bfc3; }


/* --------------------
   -------------------- Blocks / elements -------------------- */
/* * * * * *
 * * * * * * Carousels * * * * * */
.b-carousel .owl-nav > div:hover { color: #31bfc3; }

/* * * * * *
 * * * * * * Gallery * * * * * */
.b-gallery_item_overlay_inner { background: rgba(49, 191, 195, .75); }

/* * * * * *
 * * * * * * Countdown * * * * * */
.b-countdown_dash_inner { color: #31bfc3; }


/* --------------------
   -------------------- Header -------------------- */
/* * * * * *
 * * * * * * Navigation * * * * * */
.b-header_nav a:hover,
.b-header_nav_toggle:hover { color: #31bfc3 !important; }

@media (max-width: 767px) {

    .b-header_nav a.active { color: #31bfc3; }

}

@media (min-width: 768px) {

    .b-header_nav a.active:before { background-color: #31bfc3; }

}


/* --------------------
   -------------------- Sections -------------------- */
/* * * * * *
 * * * * * * Section header * * * * * */
.b-section_header__stroked h2:after,
.b-section_header__stroked h2:before { background-color: #31bfc3; }

/* --------------------
   -------------------- Features -------------------- */
/* * * * * *
 * * * * * * Intro summary icons * * * * * */
.b-features_item_icon,
.b-features_item_icon:before { color: #31bfc3; }


/* --------------------
   -------------------- Overview -------------------- */
/* * * * * *
 * * * * * * Box header * * * * * */
.b-overview_header_icon,
.b-overview_header_icon:before { color: #31bfc3; }

/* * * * * *
 * * * * * * Figures * * * * * */
.b-overview_figure { color: #31bfc3; }
.b-overview_figure svg rect { stroke: #31bfc3; }


/* --------------------
   -------------------- Counters -------------------- */
.b-counters_item h3:after,
.b-counters_item h3:before { background-color: #31bfc3; }


/* --------------------
   -------------------- Schedule -------------------- */
/* * * * * *
 * * * * * * Download * * * * * */
.b-schedule_download_frame { color: #31bfc3; }

/* * * * * *
 * * * * * * Days nav * * * * * */
.b-schedule_tabs_btn.current { background: #31bfc3 !important; }

/* * * * * *
 * * * * * * Download * * * * * */
.b-schedule_download { color: #31bfc3; }
.b-schedule_download_frame { color: #31bfc3; }

/* * * * * *
 * * * * * * Time line * * * * * */
.b-schedule_timeLine { border-left-color: #31bfc3; }
.b-schedule_timeLine:before { background: #31bfc3; }

/* * * * * *
 * * * * * * Speech details * * * * * */
.b-schedule_speech_header_dummy { background-color: #31bfc3; }

.b-schedule_speech_header_name:before,
.b-schedule_speech_header_time:before { color: #31bfc3; }

.b-schedule_speech_arrow,
.js-spoiler .b-schedule_speech_toggle:hover { color: #31bfc3; }

/* * * * * *
 * * * * * * Schedule slider * * * * * */
@media (max-width: 479px) {

    .b-schedule_slider .b-slider_control_captions .b-slider_bullet a.b-slider_active { color: #31bfc3 !important; }

}

@media (min-width: 480px) {

    .b-schedule_slider .b-slider_control_captions .b-slider_bullet a.b-slider_active { background: #31bfc3 !important; }

}

/* * * * * *
 * * * * * * Schedule day tabs * * * * * */
@media (max-width: 479px) {

    .b-schedule_day_nav a.current { color: #31bfc3 !important; }

}

@media (min-width: 480px) {

    .b-schedule_day_nav a.current { background: #31bfc3 !important; }

}


/* --------------------
   -------------------- Speakers -------------------- */
.b-speakers_view_accounts a { color: #31bfc3; }


/* --------------------
   -------------------- Pricing plans -------------------- */
/* * * * * *
 * * * * * * Plans decks * * * * * */
.b-pricing_plan_deck__popular .b-pricing_plan_deck_caption,
.b-pricing_plan_deck__popular .b-pricing_plan_price,
.b-pricing_plan_deck__popular .b-pricing_plan_price strong { color: #31bfc3; }

.b-pricing_plan_deck__popular .b-pricing_plan_deck_ribbon,

.b-pricing_plan_deck__popular .b-pricing_plan_purchase { background-color: #31bfc3; }
.b-pricing_plan_deck__popular .b-pricing_plan_purchase:hover { background-color: #2ba9ad; }

/* * * * * *
 * * * * * * Plans switcher * * * * * */
.b-pricing_switcher_box { background: #31bfc3; }
.b-pricing_switcher_box:hover { background-color: #2ba9ad; }


/* --------------------
   -------------------- FAQ -------------------- */
/* * * * * *
 * * * * * * Slider * * * * * */
.b-faq_slider .b-slider_bullet a { color: #31bfc3 !important; }
.b-faq_slider .b-slider_bullet a.b-slider_active { background-color: #31bfc3 !important; }

/* * * * * *
 * * * * * * Device * * * * * */
.b-faq_device_screen .e-frame { color: #31bfc3 !important; }


/* --------------------
   -------------------- Contacts -------------------- */
.b-contacts_details_social a,
.b-contacts_details_list a:hover { color: #31bfc3; }

/* * * * * *
 * * * * * * Map * * * * * */
.b-contacts_map_marker { color: #31bfc3; }


/* ----------------------- Plugins ----------------------- */
/* * * * * *
 * * * * * * Leaflet Popup * * * * * */
.b-leaflet_close:hover,
.b-leaflet_direction:hover { color: #31bfc3; }
