/* -----------------------
   ----------------------- Normalize ----------------------- */
html { width: 100%; height: 100%; }
body { width: 100%; height: 100%; margin: 0; }


/* -----------------------
   ----------------------- Transitions ----------------------- */
.b-leaflet_box { -webkit-transition: -webkit-transform 400ms, opacity 400ms, visibility 400ms; -moz-transition: -moz-transform 400ms, opacity 400ms, visibility 400ms; -o-transition: -o-transform 400ms, opacity 400ms, visibility 400ms; transition: transform 400ms, opacity 400ms, visibility 400ms; }

.b-leaflet_overlay,
.b-leaflet_box_content { -webkit-transition: opacity 400ms, visibility 400ms; -moz-transition: opacity 400ms, visibility 400ms; -o-transition: opacity 400ms, visibility 400ms; transition: opacity 400ms, visibility 400ms; }


/* -----------------------
   ----------------------- Add to composite layers ----------------------- */
.b-leaflet_box,
.b-leaflet_overlay { -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; backface-visibility: hidden; }


/* -----------------------
   ----------------------- 3D transforms ----------------------- */
.b-leaflet_perspective { position: relative; z-index: 25000; margin-left: auto; margin-right: auto; -webkit-perspective: 1500px; -moz-perspective: 1500px; perspective: 1500px; -webkit-transform-style: preserve-3d; -moz-transform-style: preserve-3d; transform-style: preserve-3d; }


/* -----------------------
   ----------------------- Layout ----------------------- */
.b-leaflet { display: table; position: fixed; z-index: 5000; left: 0; top: 0; right: 0; bottom: 0; width: 100%; height: 100%; overflow: hidden; border-collapse: collapse; table-layout: fixed; }
.b-leaflet_inner { display: table-cell; vertical-align: middle; }


/* ----------------------- Scroll lock ----------------------- */
.m-leaflet-outer-mode body { overflow-y: scroll; }

.m-leaflet-outer-mode .b-leaflet { position: relative; }
.m-leaflet-outer-mode .b-leaflet_locker { position: fixed; left: 0; top: 0; right: 0; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; width: 100%; overflow: hidden; }


/* ----------------------- Scroll unlock ----------------------- */
.m-leaflet-inner-mode .b-leaflet_box_content { max-height: inherit; overflow-y: scroll; overflow-x: visible; }


/* -----------------------
   ----------------------- Overlay ----------------------- */
.b-leaflet_overlay { display: none; position: fixed; z-index: 0; left: 0; top: 0; right: 0; bottom: 0; pointer-events: auto; visibility: hidden; opacity: 0; background-color: #000; }

.m-leaflet-overlay .b-leaflet_overlay { display: block; }
.m-leaflet-loading .b-leaflet_overlay { background: #000 url('../../img/loading.gif') center center no-repeat; }

/* * * Webkit bug fix * * */
.b-leaflet_overlay { -webkit-transform: translateZ(-1000px); transform: translateZ(-1000px); }

/* ----------------------- Control buttons ----------------------- */
.b-leaflet_close { position: absolute; z-index: 25; right: 0; top: 0; width: 28px; height: 28px; padding: 18px; text-align: center; color: #fff; cursor: pointer; pointer-events: auto; -webkit-box-sizing: content-box; -moz-box-sizing: content-box; box-sizing: content-box; }
.b-leaflet_close:before { font-weight: normal; font-size: 28px; line-height: 28px; }

.b-leaflet_direction { position: fixed; z-index: 5; top: 0; bottom: 0; width: 64px; text-align: center; color: #fff; cursor: pointer; pointer-events: auto; }
.b-leaflet_direction.i-icon:before { position: absolute; left: 50%; top: 50%; margin: -14px 0 0 -14px; width: 28px; height: 28px; font-weight: normal; font-size: 28px; line-height: 28px; }

.b-leaflet_prev { left: 0; }
.b-leaflet_next { right: 0; }

.b-leaflet_close,
.b-leaflet_direction { -webkit-transition: all 400ms; -moz-transition: all 400ms; -o-transition: all 400ms; transition: all 400ms; }


/* -----------------------
   ----------------------- Box ----------------------- */
.b-leaflet_box { position: relative; z-index: 25; margin-left: auto; margin-right: auto; visibility: hidden; opacity: 0; -webkit-box-sizing: content-box; -moz-box-sizing: content-box; box-sizing: content-box; }


/* ----------------------- Content types ----------------------- */
/* * * Content popup * * */
.b-leaflet_box { padding: 45px 30px; font: 300 1.6em/1.5 'Lato', sans-serif; letter-spacing: .025em; color: #333; background: #fff; }

@media (min-width: 768px) {

    .b-leaflet_box { padding: 45px 60px; }

}

.b-leaflet_box .b-section_header,
.b-leaflet_box .b-section_header h2 { color: inherit !important; }

/* * * Image popup * * */
.m-leaflet-type-image { padding: 0; background: transparent; }
.m-leaflet-type-image .b-leaflet_box_content { overflow: hidden; }
.m-leaflet-type-image .b-leaflet_box_content img { display: block; max-width: 100%; }

/* * * IFrame popup * * */
.m-leaflet-type-iframe { padding: 0; background: transparent; }
.m-leaflet-type-iframe .b-leaflet_box_content { overflow: hidden; max-height: 480px; }
.m-leaflet-type-iframe .b-leaflet_box_content iframe { display: block; width: 100%; max-width: 640px; height: 480px; margin: 0 auto; -webkit-backface-visibility: visible; -webkit-transform: translateZ(0px); }


/* ----------------------- Box show methods ----------------------- */

/* * * Scale * * */
.m-leaflet-scale-transition .b-leaflet_box { -webkit-transform: scale(.6, .6); -moz-transform: scale(.6, .6); -ms-transform: scale(.6, .6); transform: scale(.6, .6); }

/* * * Super scale * * */
.m-leaflet-superScale-transition .b-leaflet_box { -webkit-transform: scale(1.4, 1.4); -moz-transform: scale(1.4, 1.4); -ms-transform: scale(1.4, 1.4); transform: scale(1.4, 1.4); }

/* * * Drop * * */
.m-leaflet-drop-transition .b-leaflet_box { -webkit-transform: translate(0, -150%); -moz-transform: translate(0, -150%); -ms-transform: translate(0, -150%); transform: translate(0, -150%); }

/* * * Newspaper * * */
.m-leaflet-newspaper-transition .b-leaflet_box { -webkit-transform: scale(.2, .2) rotate(720deg); -moz-transform: scale(.2, .2) rotate(720deg); -ms-transform: scale(.2, .2) rotate(720deg); transform: scale(.2, .2) rotate(720deg); }


/* * * Drop 3d * * */
.m-leaflet-drop3d-transition .b-leaflet_box { -webkit-transform-origin: top; -moz-transform-origin: top; -ms-transform-origin: top; transform-origin: top; -webkit-transform: translate3d(0, -100px, 0) rotateX(-90deg); -moz-transform: translate3d(0, -100px, 0) rotateX(-90deg); -ms-transform: translate3d(0, -100px, 0) rotateX(-90deg); transform: translate3d(0, -100px, 0) rotateX(-90deg); }

/* * * Flip 3d * * */
.m-leaflet-flip3d-transition .b-leaflet_box { -webkit-transform-origin: center top; -moz-transform-origin: center top; -ms-transform-origin: center top; transform-origin: center top; -webkit-transform: rotateY(-70deg); -moz-transform: rotateY(-70deg); -ms-transform: rotateY(-70deg); transform: rotateY(-70deg); }

/* * * Flip 3d Vertical * * */
.m-leaflet-flip3dVertical-transition .b-leaflet_box { -webkit-transform-origin: 0 50%; -moz-transform-origin: 0 50%; -ms-transform-origin: 0 50%; transform-origin: 0 50%; -webkit-transform: rotateX(-70deg); -moz-transform: rotateX(-70deg); -ms-transform: rotateX(-70deg); transform: rotateX(-70deg); }

/* * * Side fall * * */
.m-leaflet-sideFall-transition .b-leaflet_box { -webkit-transform-origin: 50%; -moz-transform-origin: 50%; -ms-transform-origin: 50%; transform-origin: 50%; -webkit-transform: translate(30%) translateZ(600px) rotate(10deg); -moz-transform: translate(30%) translateZ(600px) rotate(10deg); -ms-transform: translate(30%) translateZ(600px) rotate(10deg); transform: translate(30%) translateZ(600px) rotate(10deg); }
