/* -------------------- Table of contents --------------------

1. Sections
    1.1. Intro
    1.1. Counters
    1.1. Subscribe
    1.1. SignUp

----------------------- Table of content -------------------- */

/* --------------------
   -------------------- Intro -------------------- */
.b-intro__background { background-image: url('../img/slide-2.jpg'); }

.b-intro__slider .b-slider_item__first .b-intro_wrapper { background-image: url('../img/slide-1.jpg'); }
.b-intro__slider .b-slider_item__second .b-intro_wrapper { background-image: url('../img/slide-2.jpg'); }

.m-touch .b-intro__video { background-image: url('../img/slide-2.jpg'); }

/* --------------------
   -------------------- Counters -------------------- */
.b-counters { background-image: url('../img/slide-2.jpg'); }

/* --------------------
   -------------------- Subscribe -------------------- */
.b-subscribe { background-image: url('../img/slide-2.jpg'); }

/* --------------------
   -------------------- SignUp -------------------- */
.b-signUp { background-image: url('../img/slide-2.jpg'); }
